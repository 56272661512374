import React, { useContext, useEffect, useState } from "react";
import "./FeedbackForm.css";
import {
  CloseOutlined,
  InfoCircleOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import {
  containsAlphabetsOrNumbers,
  ratingShades,
  submit_text,
} from "../../../../constants/GlobalConstants";
import { Modal, Switch, message } from "antd";
import { Base_Url_Admin } from "../../../../Utils/urls";
import { AuthContext } from "../../../../Context/AuthContext";
import AdminModal from "../AdminModal/AdminModal";
let warning_text = "";
export default function FeedbackForm({
  questions,
  setQuestions,
  getFeedbackQuestions,
  getUserFeedback,
  editQuestion,
  setEditQuestion,
}) {
  // let x = {};
  const [delete_ids, setDeleteIds] = useState([]);
  const { user, getToken } = useContext(AuthContext);

  // let editedQuestions = questions.map((item, index) => {
  //   x[item.question_id] = {
  //     is_rating: item.is_rating,
  //     is_required: item.is_required,
  //     question: item.question,
  //   };
  // });
  const [showQuestionOptions, setShowQuestionOptions] = useState(false);
  const [newQuestions, setNewQuestions] = useState([]);
  const [openModal, setOpenModal] = useState({});
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();
  const [disable, setDisable] = useState(false);
  const handleNewQuestion = (e, value) => {
    let prevQues = newQuestions;
    const new_question_val = {
      is_rating: false,
      question: "",
      is_required: false,
      is_long_answer: false,
      title: "",
    };
    if (value !== "text") new_question_val.is_rating = true;
    prevQues.push(new_question_val);
    setNewQuestions(prevQues);
    setShowQuestionOptions(false);
    return;
  };
  const ValidationCheck = () => {
    let new_questions = [...questions, ...newQuestions];
    for (let i = 0; i < new_questions.length; i++) {
      if (
        !containsAlphabetsOrNumbers(new_questions[i]?.question) ||
        !containsAlphabetsOrNumbers(new_questions[i]?.title)
      ) {
        message.error(`Empty fields are not allowed!`);
        setTimeout(() => {
          setDisable(false);
        }, 3500);
        return;
      }
    }
    setOpenModal({
      content:
        questions.length + newQuestions.length > 2
          ? submit_text
          : "To proceed, please ensure your feedback form includes a minimum of three questions.",
      func: (e) => handleSaveForm(e),
    });
  };
  const handleSaveForm = async (e) => {
    e.preventDefault();
    if (disable) return;
    setDisable(true);
    let new_questions = [...questions, ...newQuestions];
    if (delete_ids.length !== 0) {
      let ids = {
        question_ids: delete_ids,
      };
      let accessToken = await getToken();
      let response = await fetch(`${Base_Url_Admin}/feedback_admin`, {
        method: "DELETE",
        body: JSON.stringify(ids),
        headers: {
          "Content-Type": "application/json",
          // Authorization: "Bearer " + token,
          "X-Api-Token": accessToken,
          // Authorization: "Bearer " + deloitteChatToken,
        },
      });
      setDeleteIds([]);
    }
    let accessToken = await getToken();
    let response = await fetch(`${Base_Url_Admin}/feedback_admin`, {
      method: "POST",
      body: JSON.stringify(new_questions),
      headers: {
        "Content-Type": "application/json",
        // Authorization: "Bearer " + token,
        "X-Api-Token": accessToken,
        // Authorization: "Bearer " + deloitteChatToken,
      },
    });
    let res = await response.json();
    setOpenModal({});
    setDisable(false);
    if (response.status === 400) {
      message.error("Question already exists !!");
      return;
    }
    setEditQuestion(false);
    message.success(`Form successfully saved!`);
    getFeedbackQuestions();
    getUserFeedback();
    return;
  };

  const showAnswerBox = (value) => {
    return value ? (
      <div className="admin-feedback-rating-row">
        {ratingShades.map((item, index) => {
          return (
            <div
              className="admin-feedback-rating-no"
              key={index}
              style={{ border: `3px solid  ${item}` }}
            >
              {index + 1}
            </div>
          );
        })}
      </div>
    ) : (
      <div className="show-text-area"></div>
    );
  };

  const handleDeleteQuestion = async (value, id = -1) => {
    if (questions.length + newQuestions.length < 4) {
      // warning_text =
      //   "Please ensure the following prior to deletion:1.The form contains a minimum of three questions.2.You have downloaded the user feedback for the question from the 'Feedback Responses' section.";
      // setOpenModal(true);
      setOpenDeleteModal(true);
      return;
    }
    let to_check = id === -1 ? [...newQuestions] : [...questions];
    const new_array = to_check.filter((item, index) => index !== value);
    if (id !== -1) setQuestions(new_array);
    else setNewQuestions(new_array);
    let current_ids = delete_ids;
    if (id !== -1) {
      current_ids.push(id);
      setDeleteIds(current_ids);
    }
    setOpenModal({});
    setOpenDeleteModal(false);
    return;
  };

  const handleChange = (e, index, item, is_new_question = false) => {
    if (item === "title" && e.target.value.length > 75) return;
    const new_questions_value = is_new_question
      ? [...newQuestions]
      : [...questions];
    // new_questions_value[index] = {
    //   ...new_questions_value[index],
    //   question: e.target.value,
    // };
    new_questions_value[index][item] = e.target.value;
    if (is_new_question) setNewQuestions(new_questions_value);
    else setQuestions(new_questions_value);
    return;
  };

  const handleRequiredChange = (e, index, option, is_new_question = false) => {
    const new_questions_value = is_new_question
      ? [...newQuestions]
      : [...questions];
    if (option === "required")
      new_questions_value[index] = {
        ...new_questions_value[index],
        is_required: e,
      };
    else
      new_questions_value[index] = {
        ...new_questions_value[index],
        is_long_answer: e,
      };
    if (is_new_question) setNewQuestions(new_questions_value);
    else setQuestions(new_questions_value);
    return;
  };

  return (
    <>
      {contextHolder}
      {!editQuestion ? (
        <>
          {questions.map((item, index) => {
            return (
              <div className="feedback-question-box-admin">
                <span className="feedback-form-question-text">
                  {item.is_required && (
                    <img src="/assets/_.svg" className="imp-logo-pos" alt="" />
                  )}{" "}
                  {index + 1}. {item.question}
                </span>
                {showAnswerBox(item?.is_rating)}
                <div className="title-section">
                  <span className="provide-header-text">
                    Title for the Feedback Responses section
                  </span>
                  <input
                    className="title-input-box"
                    disabled
                    value={item?.title}
                  />
                </div>
              </div>
            );
          })}
        </>
      ) : (
        <>
          {questions.map((item, index) => {
            return (
              <div className="question-edit-box">
                <div className="delete-question-pos">
                  <img
                    src="/assets/DeleteOutlinedConfig.svg"
                    alt=""
                    onClick={() => {
                      questions.length + newQuestions.length > 3
                        ? setOpenModal({
                            content:
                              "Please download the user feedback for the question from the 'Feedback Responses' section before deleting it.Are you sure you want to delete this question?",
                            func: () =>
                              handleDeleteQuestion(index, item?.question_id),
                          })
                        : handleDeleteQuestion(index, item?.question_id);
                    }}
                    className="cursor-p"
                  />
                </div>
                <span className="question-edit-no-box">
                  {item.is_required && (
                    <img src="/assets/_.svg" className="imp-logo-pos" alt="" />
                  )}
                  {index + 1}.
                  <input
                    onChange={(e) => {
                      handleChange(e, index, "question");
                    }}
                    value={item?.question}
                    className="question-label-box"
                  />
                </span>
                {showAnswerBox(item?.is_rating)}
                <div className="title-section">
                  <span className="provide-header-text">
                    Add a concise title that should appear in the Feedback
                    response section
                  </span>
                  <input
                    className="title-input-box"
                    onChange={(e) => {
                      handleChange(e, index, "title");
                    }}
                    value={item?.title}
                  />
                  <span className="title-text-limit">
                    Limit the header to 25 characters
                  </span>
                </div>
                <div className="divider-rating" />
                <div className="bottom-question-section">
                  {!item?.is_rating && (
                    <span>
                      Long Answer:{" "}
                      <Switch
                        checked={item?.is_long_answer}
                        onChange={(e) => {
                          handleRequiredChange(e, index, "long_answer");
                        }}
                      />
                    </span>
                  )}
                  <span>
                    Required:{" "}
                    <Switch
                      checked={item?.is_required}
                      onChange={(e) => {
                        handleRequiredChange(e, index, "required");
                      }}
                    />
                  </span>
                </div>
              </div>
            );
          })}
          {newQuestions.length !== 0 &&
            newQuestions.map((item, index) => {
              return (
                <div className="question-edit-box">
                  <div className="delete-question-pos">
                    <img
                      src="/assets/DeleteOutlinedConfig.svg"
                      alt=""
                      onClick={() => {
                        questions.length + newQuestions.length > 3
                          ? setOpenModal({
                              content:
                                "Are you sure you want to delete this question?",
                              func: () => handleDeleteQuestion(index),
                            })
                          : handleDeleteQuestion(index);
                      }}
                      className="cursor-p"
                    />
                  </div>
                  <span className="question-edit-no-box">
                    {item.is_required && (
                      <img
                        src="/assets/_.svg"
                        className="imp-logo-pos"
                        alt=""
                      />
                    )}
                    {index + 1 + questions.length}.
                    <input
                      onChange={(e) => {
                        handleChange(e, index, "question", true);
                      }}
                      value={item?.question}
                      className="question-label-box"
                    />
                  </span>
                  {showAnswerBox(item?.is_rating)}
                  <div className="title-section">
                    <span className="provide-header-text">
                      Add a concise title that should appear in the Feedback
                      response section
                    </span>
                    <input
                      className="title-input-box"
                      onChange={(e) => {
                        handleChange(e, index, "title", true);
                      }}
                      value={item?.title}
                    />
                    <span className="title-text-limit">
                      Limit the header to 25 characters
                    </span>
                  </div>
                  <div className="divider-rating" />
                  <div className="bottom-question-section">
                    {!item?.is_rating && (
                      <span>
                        Long Answer:{" "}
                        <Switch
                          checked={item?.is_long_answer}
                          onChange={(e) => {
                            handleRequiredChange(e, index, "long_answer", true);
                          }}
                        />
                      </span>
                    )}
                    <span>
                      Required:{" "}
                      <Switch
                        checked={item?.is_required}
                        onChange={(e) => {
                          handleRequiredChange(e, index, "required", true);
                        }}
                      />
                    </span>
                  </div>
                </div>
              );
            })}
          <div className="save-button-bottom-section">
            {showQuestionOptions ? (
              <div className="add-question-options">
                <CloseOutlined
                  className="plus-add-question-icon"
                  onClick={() => {
                    setShowQuestionOptions(false);
                  }}
                />
                <div className="multiple-option-box">
                  <span
                    className="question-option-box"
                    onClick={(e) => {
                      handleNewQuestion(e, "text");
                    }}
                  >
                    <img src="/assets/TextLabel.svg" alt="" /> Text
                  </span>
                  <span
                    className="question-option-box"
                    onClick={(e) => {
                      handleNewQuestion(e, "rating");
                    }}
                  >
                    <img src="/assets/Rating.svg" alt="" />
                    Rating
                  </span>
                </div>
              </div>
            ) : (
              <div
                className="add-new-button"
                onClick={() => {
                  setShowQuestionOptions(true);
                }}
              >
                <PlusOutlined /> <span>Add new</span>
              </div>
            )}
            <div
              className={
                disable
                  ? "edit-question-button cursor-n"
                  : "edit-question-button"
              }
              onClick={ValidationCheck}
            >
              Save
            </div>
          </div>
        </>
      )}
      <Modal
        open={openDeleteModal}
        title={
          <div className="error-modal">
            <InfoCircleOutlined className="info-icon" color="#007CB0" />
            <div className="error-content">
              <span>Please ensure the following prior to deletion:</span>
              <ol>
                <li>The form contains a minimum of three questions.</li>
                <li>
                  You have downloaded the user feedback for the question from
                  the 'Feedback Responses' section.
                </li>
              </ol>
            </div>
          </div>
        }
        closable={false}
        width={550}
        footer={[
          <div className="setting-end">
            <div
              className="edit-question-button"
              onClick={() => {
                setOpenDeleteModal(false);
              }}
            >
              Ok
            </div>
          </div>,
        ]}
        onCancel={() => {
          setOpenDeleteModal(false);
        }}
      ></Modal>
      <AdminModal openModal={openModal} setOpenModal={setOpenModal} />
    </>
  );
}
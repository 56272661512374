import React, { useContext, useEffect, useState } from "react";
import "./Configuration.css";

import { InfoCircleOutlined, SmallDashOutlined } from "@ant-design/icons";
import Checkbox from "../../../Components/Checkbox/Checkbox";
import { Modal, Spin, message } from "antd";
import { Base_Url_Admin } from "../../../../../Utils/urls";
import { AuthContext } from "../../../../../Context/AuthContext";
import AdminModal from "../../../Components/AdminModal/AdminModal";
import {
  delete_text,
  delete_text_multiple,
  submit_text,
} from "../../../../../constants/GlobalConstants";
let filterAllOptions = {};

export default function Configuration() {
  const { user, getToken } = useContext(AuthContext);
  const [selectedFilter, setSelectedFilter] = useState(1);
  const [isRecentAdded, setRecentAdded] = useState({});
  const [currentInput, setCurrentInput] = useState("");
  const [filterInput, setFilterInput] = useState("");
  const [isRecentSelected, setRecentSelected] = useState([]);
  const [openDropdown, setOpenDropdown] = useState(0);
  const [showFilterInput, setShowFilterInput] = useState(0);
  const [openModal, setOpenModal] = useState({});
  const [messageApi, contextHolder] = message.useMessage();
  const [isLoading, setIsLoading] = useState(false);
  const [openContentModal, setOpenContentModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState(0);
  const handleChangeInput = (e) => {
    setCurrentInput(e.target.value);
    return;
  };
  const isEmptyObject = (obj) => {
    return Object.keys(obj).length === 0 && obj.constructor === Object;
  };
  const handleChangeFilterInput = (e) => {
    setFilterInput(e.target.value);
    return;
  };
  const [filterOptions, setFilterOptions] = useState([]);
  const [filterSubOptions, setFilterSubOptions] = useState([]);
  const [isDefault, setDefault] = useState([]);
  const handleChange = (e, value) => {
    let new_vals = [...isDefault];
    if (!e.target.checked) new_vals = new_vals.filter((item) => item !== value);
    else new_vals.push(value);
    setDefault(new_vals);
    return;
  };
  const handleRecentChange = (e, value) => {
    let new_vals = [...isRecentSelected];
    if (!e.target.checked) new_vals = new_vals.filter((item) => item !== value);
    else new_vals.push(value);
    setRecentSelected(new_vals);
    return;
  };
  const handleDefault = (e) => {
    if (!e.target.checked) {
      setDefault([]);
      return;
    }
    let to_delete_ids = [];
    filterSubOptions[filterOptions[selectedFilter - 1]?.value].map((item) =>
      to_delete_ids.push(item?.value)
    );
    setDefault(to_delete_ids);
    return;
  };
  const handleNewValues = (e) => {
    if (!e.target.checked) {
      setRecentSelected([]);
      return;
    }
    setRecentSelected(isRecentAdded[filterOptions[selectedFilter - 1].value]);
    return;
  };
  const addValue = async (e) => {
    if (currentInput.length === 0) return;
    let newValues = isRecentAdded[filterOptions[selectedFilter - 1].value];
    try {
      let payload = {
        value: currentInput,
      };
      const params = new URLSearchParams();
      params.append("filter_id", filterOptions[selectedFilter - 1].id);
      let accessToken = await getToken();
      let response = await fetch(
        `${Base_Url_Admin}/configuration_filter_values?${params}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "X-Api-Token": accessToken,
          },
          body: JSON.stringify(payload),
        }
      );
      let message = await response.json();
      if (message?.message === "Filter already exist in DB") {
        messageApi.open({
          type: "error",
          content: "The filter value already exists!",
        });
        setCurrentInput("");
      }
      if (response.status === 200) {
        newValues.push(currentInput);
        setCurrentInput("");
        setRecentAdded({
          ...isRecentAdded,
          [filterOptions[selectedFilter - 1].value]: newValues,
        });
      }
      // console.log("Message:", message);
    } catch (e) {
      console.log("error:", e);
    }
    return;
  };
  const handleRemove = async (value, isNew) => {
    let to_delete = value;
    try {
      if (isLoading) return;
      else setIsLoading(true);
      if (typeof value !== "object") {
        to_delete = [value];
      }
      const params = new URLSearchParams();
      params.append("filter_id", filterOptions[selectedFilter - 1].id);
      let payload = {
        values: to_delete,
      };
      let accessToken = await getToken();
      let response = await fetch(
        `${Base_Url_Admin}/configuration_filter_values?${params}`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            "X-Api-Token": accessToken,
          },
          body: JSON.stringify(payload),
        }
      );
      const result = await response.json();
      message.success(
        to_delete.length > 1
          ? "The filter values have been deleted."
          : "The filter value has been deleted."
      );
      setOpenModal({});
      setIsLoading(false);
      if (isNew) {
        let new_vals = [...isRecentSelected];
        let new_adds = isRecentAdded[filterOptions[selectedFilter - 1].value];
        new_vals = isRecentSelected.filter((item) => !to_delete.includes(item));
        new_adds = new_adds.filter((item) => !to_delete.includes(item));
        setRecentSelected(new_vals);
        setRecentAdded({
          ...isRecentAdded,
          [filterOptions[selectedFilter - 1].value]: new_adds,
        });
        return;
      }
      getAllFilters();
      return;
    } catch (e) {
      console.log("Error:", e);
    }
  };
  const handleKeyPress = (e) => {
    // Check if the Enter key is pressed (keyCode 13) or (key "Enter")
    if (e.key === "Enter") {
      // Call another function here
      addValue();
    }
  };

  const getAllFilters = async () => {
    try {
      // setIsLoading(true);
      setFilterOptions([]);
      setFilterSubOptions([]);
      let accessToken = await getToken();
      let response = await fetch(`${Base_Url_Admin}/configuration_filters`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "X-Api-Token": accessToken,
        },
      });
      let filters = await response.json();
      let data = filters?.filters;
      let filterTitles = [];
      let emptyState = {};
      for (const [key, value] of Object.entries(data)) {
        filterTitles.push({
          value: key,
          id: data[key]?.id,
          settings: data[key]?.settings,
        });
        emptyState[key] = [];
        let z = data[key].value.map((item) => ({
          value: item?.value,
          label: item?.value,
          id: item?.id,
        }));
        filterAllOptions[
          key
          // .replace(/\s/g, "")
        ] = z;
      }
      setRecentAdded(emptyState);
      setRecentSelected([]);
      setFilterSubOptions(filterAllOptions);
      setFilterOptions(filterTitles);
      // setIsLoading(false);
    } catch (e) {
      console.log("Error:", e);
    }
  };

  const handleAddFilter = async (e) => {
    e.preventDefault();
    // if (filterInput.length === 0) return;
    try {
      if (isLoading) return;
      else setIsLoading(true);
      setShowFilterInput(0);
      let value = filterInput;
      setFilterInput("");
      let payload = {
        filter_name: value,
      };
      let accessToken = await getToken();
      let response = await fetch(`${Base_Url_Admin}/configuration_filters`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-Api-Token": accessToken,
        },
        body: JSON.stringify(payload),
      });
      let result = await response.json();
      if (result?.message === "Filter already exist in DB") {
        messageApi.open({
          type: "error",
          content: "The filter already exists!",
        });
        return;
      }
      message.success(result?.message);
      setOpenModal({});
      setIsLoading(false);
      setOpenContentModal(true);
      getAllFilters();
    } catch (e) {
      console.log("Error:", e);
    }
    return;
  };

  const handleDeleteFilter = async (value) => {
    if (isLoading) return;
    else setIsLoading(true);
    setOpenDropdown(0);
    const params = new URLSearchParams();
    params.append("filter_id", value);
    let accessToken = await getToken();
    let response = await fetch(
      `${Base_Url_Admin}/configuration_filters?${params}`,
      {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          "X-Api-Token": accessToken,
        },
      }
    );
    let result = await response.json();
    message.success(result?.message);
    setOpenModal({});
    setIsLoading(false);
    getAllFilters();
    return;
  };
  const handleEditFilter = async (value, prevName) => {
    try {
      if (isLoading) return;
      else setIsLoading(true);
      // if (filterInput.length === 0 || filterInput === prevName) return;
      let payload = {
        filter_name: filterInput,
      };
      const params = new URLSearchParams();
      params.append("filter_id", value);
      let accessToken = await getToken();
      let response = await fetch(
        `${Base_Url_Admin}/configuration_filters?${params}`,
        {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
            "X-Api-Token": accessToken,
          },
          body: JSON.stringify(payload),
        }
      );
      let result = await response.json();
      setOpenModal({});
      setIsLoading(false);
      message.success(result?.message);
      setShowFilterInput(0);
      setFilterInput("");
      getAllFilters();
    } catch (e) {
      console.log("Error:", e);
    }
  };
  useEffect(() => {
    setDefault([]);
    setCurrentInput("");
    setRecentSelected([]);
  }, [selectedFilter]);

  useEffect(() => {
    getAllFilters();
  }, []);

  if (filterOptions.length === 0 || filterSubOptions.length === 0)
    return (
      <span className="config-main-loader">
        <Spin />
      </span>
    );
  return (
    <>
      {contextHolder}
      <div
        className="configuration-box"
        onClick={() => {
          openDropdown === 0 ? <></> : setOpenDropdown(0);
        }}
      >
        <div className="left-side-heading">
          <span className="upload-content">Manage filters</span>
          {/* <span className="sub-content">
            Use this section to customize filters and their values to tailor the user experience to your preferences.
          </span> */}
        </div>
        <div className="filter-main-box">
          <div className="filters-left-box">
            <div className="active-filter-heading">Active filters</div>
            <div className="filters-list-box-config">
              {filterOptions.map((item, index) => {
                return showFilterInput === index + 1 ? (
                  <div className="filter-input-box-config">
                    <input
                      placeholder="Add filter"
                      className="add-filter-input"
                      value={filterInput}
                      onChange={handleChangeFilterInput}
                    />
                    <img
                      src="/assets/CheckCircle.svg"
                      className="cursor-p"
                      onClick={
                        showFilterInput === -1
                          ? (e) => {
                              if (filterInput.length === 0) return;
                              setOpenModal({
                                content:
                                  "Are you sure that you want to add this filter?",
                                func: (e) => handleAddFilter(e),
                              });
                            }
                          : () => {
                              // handleEditFilter(item?.id, item?.value);
                              if (
                                filterInput.length === 0 ||
                                filterInput === item?.value
                              )
                                return;
                              setOpenModal({
                                content:
                                  "Are you sure that you want to rename this filter?",
                                func: () =>
                                  handleEditFilter(item?.id, item?.value),
                              });
                            }
                      }
                    />
                  </div>
                ) : (
                  <div
                    className={
                      index + 1 === selectedFilter
                        ? "filter-box-selected-config filter-box-config"
                        : "filter-box-config"
                    }
                    onClick={() => {
                      setSelectedFilter(index + 1);
                      openDropdown === 0 ? <></> : setOpenDropdown(0);
                    }}
                  >
                    {item.value}&nbsp;
                    {index + 1 === selectedFilter ? (
                      (item?.settings?.isDelete ||
                        item?.settings?.isRename) && (
                        <SmallDashOutlined
                          className="dash-color"
                          onClick={() => {
                            openDropdown === index + 1
                              ? setOpenDropdown(0)
                              : setOpenDropdown(index + 1);
                            // handleDropDown(index)
                          }}
                        />
                      )
                    ) : (
                      <></>
                    )}
                    {openDropdown === index + 1 &&
                      (item?.settings?.isDelete ||
                        item?.settings?.isRename) && (
                        <div className="dropdown-filter">
                          {item?.settings?.isDelete && (
                            <span
                              className={
                                selectedItem === 1
                                  ? "hover-configuration dropdown-content"
                                  : "dropdown-content"
                              }
                              onClick={() => {
                                setOpenModal({
                                  content: delete_text + " filter?",
                                  func: () => handleDeleteFilter(item?.id),
                                });
                              }}
                              onMouseOver={() => {
                                setSelectedItem(1);
                              }}
                              onMouseLeave={() => {
                                setSelectedItem(0);
                              }}
                            >
                              Delete
                            </span>
                          )}
                          {item?.settings?.isRename && (
                            <span
                              className={
                                selectedItem === 2
                                  ? "hover-configuration dropdown-content"
                                  : "dropdown-content"
                              }
                              onClick={() => {
                                setShowFilterInput(index + 1);
                                setOpenDropdown(0);
                                setFilterInput(item.value);
                              }}
                              onMouseOver={() => {
                                setSelectedItem(2);
                              }}
                              onMouseLeave={() => {
                                setSelectedItem(0);
                              }}
                            >
                              Rename
                            </span>
                          )}
                        </div>
                      )}
                  </div>
                );
              })}
              {showFilterInput === -1 && (
                <div className="filter-input-box-config">
                  <input
                    placeholder="Add filter"
                    className="add-filter-input-config"
                    value={filterInput}
                    onChange={handleChangeFilterInput}
                  />
                  {filterInput.length !== 0 && (
                    <img
                      src="/assets/CheckCircle.svg"
                      className="cursor-p"
                      onClick={(e) => {
                        if (filterInput.length === 0) return;
                        setOpenModal({
                          content:
                            "Are you sure that you want to add this filter?",
                          func: (e) => handleAddFilter(e),
                        });
                      }}
                    />
                  )}
                </div>
              )}
            </div>
            <div
              className="add-filter-box-config"
              onClick={() => {
                showFilterInput !== -1
                  ? setShowFilterInput(-1)
                  : setShowFilterInput(0);
              }}
            >
              <span>Add filter</span>
              <img src="/assets/PlusCircle.svg" />
            </div>
          </div>
          {filterOptions.length !== 0 && (
            <div className="filters-right-box">
              <div className="right-header">
                <span className="header-heading">
                  {filterOptions[selectedFilter - 1]?.value} values
                </span>
                <div className="filter-option-box-config">
                  <input
                    type="text"
                    value={currentInput}
                    onChange={handleChangeInput}
                    className="filter-input-config"
                    placeholder="Add a value"
                    onKeyDown={handleKeyPress}
                  />
                  <div className="add-filter-option-config" onClick={addValue}>
                    Add
                  </div>
                </div>
              </div>
              {isRecentAdded[filterOptions[selectedFilter - 1]?.value]
                ?.length !== 0 && (
                <div className="filters-category-content-box">
                  <div className="category-header">
                    <div className="recently-added-content">
                      <Checkbox
                        label="Recently Added"
                        checked={isRecentSelected.length !== 0}
                        onChange={handleNewValues}
                        heading={
                          isRecentSelected?.length !==
                          isRecentAdded[
                            filterOptions[selectedFilter - 1]?.value
                          ]?.length
                        }
                      />
                      <span className="checkbox-text">
                        &nbsp;{"("}click on the checkboxes to delete values
                        {")"}
                      </span>
                    </div>
                    {isRecentSelected.length !== 0 && (
                      <img
                        src="/assets/DeleteOutlinedConfig.svg"
                        className="cursor-p"
                        onClick={() => {
                          setOpenModal({
                            content:
                              typeof isRecentSelected === "string"
                                ? delete_text + " value?"
                                : delete_text_multiple,
                            func: () => handleRemove(isRecentSelected, true),
                          });
                        }}
                      />
                    )}
                  </div>

                  <div className="filters-content-box">
                    {isRecentAdded[
                      filterOptions[selectedFilter - 1]?.value
                    ]?.map((item, index) => {
                      return (
                        <div className="checkbox-section">
                          <Checkbox
                            label={item}
                            checked={isRecentSelected.includes(item)}
                            onChange={(e) => {
                              handleRecentChange(e, item);
                            }}
                          />
                          {isRecentSelected.includes(item) && (
                            <img
                              src="/assets/DeleteOutlinedConfig.svg"
                              className="small-delete"
                              onClick={() => {
                                setOpenModal({
                                  content:
                                    typeof item === "string"
                                      ? delete_text + " value?"
                                      : delete_text_multiple,
                                  func: () => handleRemove(item, true),
                                });
                              }}
                            />
                          )}
                        </div>
                      );
                    })}
                  </div>
                </div>
              )}
              <div className="filters-category-content-box">
                {filterSubOptions[filterOptions[selectedFilter - 1]?.value]
                  ?.length > 0 && (
                  <div className="category-header">
                    <div className="recently-added-content">
                      <Checkbox
                        label="Default"
                        checked={isDefault.length !== 0}
                        onChange={(e) => {
                          handleDefault(e);
                        }}
                        heading={
                          filterSubOptions[
                            filterOptions[selectedFilter - 1]?.value
                          ].length !== isDefault.length
                        }
                      />
                      <span className="checkbox-text">
                        &nbsp;{"("}click on the checkboxes to delete a value
                        {")"}
                      </span>
                    </div>
                    {isDefault.length !== 0 && (
                      <img
                        src="/assets/DeleteOutlinedConfig.svg"
                        className="cursor-p"
                        onClick={() => {
                          setOpenModal({
                            content:
                              typeof isDefault === "string"
                                ? delete_text + " value?"
                                : delete_text_multiple,
                            func: () => handleRemove(isDefault, false),
                          });
                        }}
                      />
                    )}
                  </div>
                )}

                <div className="filters-content-box">
                  {filterSubOptions[
                    filterOptions[selectedFilter - 1]?.value
                  ]?.map((item, index) => {
                    return (
                      <div className="checkbox-section">
                        <Checkbox
                          key={index}
                          label={item.value}
                          checked={isDefault.includes(item?.value)}
                          onChange={(e) => {
                            handleChange(e, item?.value);
                          }}
                        />
                        {isDefault.includes(item?.value) && (
                          <img
                            src="/assets/DeleteOutlinedConfig.svg"
                            className="small-delete"
                            onClick={() => {
                              setOpenModal({
                                content:
                                  typeof item?.value === "string"
                                    ? delete_text + " value?"
                                    : delete_text_multiple,
                                func: () => handleRemove(item?.value, false),
                              });
                            }}
                          />
                        )}
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          )}
        </div>
        <Modal
          open={openContentModal}
          title={
            <div className="error-modal">
              <InfoCircleOutlined className="info-icon" color="#007CB0" />
              <div className="error-content">
                {/* <span>Please ensure the following prior to deletion:</span> */}
                {/* To make the new filter available to PPMDs, map it to the
                existing resources or add new resources under Recommended
                Resources section. */}
                To make the new filter available to PPMDs, please ensure the
                following:
                <ol>
                  <li>Add values for the filter</li>
                  <li>
                    Map the values to the existing resources or to new resources
                    through Recommended Resources section
                  </li>
                </ol>
              </div>
            </div>
          }
          closable={false}
          width={550}
          footer={[
            <div className="setting-end">
              <div
                className="edit-question-button"
                onClick={() => {
                  setOpenContentModal(false);
                }}
              >
                Ok
              </div>
            </div>,
          ]}
          onCancel={() => {
            setOpenContentModal(false);
          }}
        ></Modal>
        <AdminModal
          openModal={openModal}
          setOpenModal={setOpenModal}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
        />
      </div>
    </>
  );
}
import React, { useContext, useEffect, useRef, useState } from "react";
import "./UploadTraining.css";
import { Modal, Spin, Switch, TimePicker, message } from "antd";
import Select from "react-select";
import BulkUpload from "../BulkUpload/BulkUpload";
import Trainings from "../../Pages/Main/QuesGenAI/Trainings/Trainings";
import {
  containsAlphabetsOrNumbers,
  customStyles,
  submit_text,
  token,
} from "../../../../constants/GlobalConstants";
import { Base_Url_Admin } from "../../../../Utils/urls";
import { AuthContext } from "../../../../Context/AuthContext";
import dayjs from "dayjs";

export default function UploadTraining({
  showTraining,
  isEditTraining,
  setEditTraining,
  isViewSection,
  setViewSection,
  setDeleteTraining,
  getRecommendedTrainings,
  setShowTraining,
  filterOptions,
  page,
}) {
  const { user, getToken } = useContext(AuthContext);
  const textareaRef = useRef(null);
  const parseTimeFromString = (timeString) => {
    if (timeString.length === 0) return;
    let [hours, minutes] = [0, 0];
    if (timeString.includes("h")) {
      [hours, minutes] = timeString.split(/[hHmM]/).map(Number);
    } else {
      const extractedNumber = parseInt(timeString, 10);
      [hours, minutes] = [0, extractedNumber];
    }
    const defaultTime = new Date();
    defaultTime.setHours(hours);
    defaultTime.setMinutes(minutes);
    return dayjs(defaultTime);
  };

  const [formData, setFormData] = useState(
    showTraining
      ? {
          description: showTraining?.description,
          name: showTraining?.name,
          duration: showTraining?.duration,
          link: showTraining?.link,
          dynamicFields: Object.keys(showTraining.filters).map((key) => {
            return {
              [key]: showTraining.filters[key].map((item) => item.id),
            };
          }),
        }
      : {
          name: "",
          description: "",
          duration: "",
          link: "",
          dynamicFields: Object.keys(filterOptions).map((key) => {
            return {
              [key]: [],
            };
          }),
        }
  );

  const [disable, setDisable] = useState(false);
  // let defaultTrainingValue = {};
  // if (showTraining) {
  //   defaultTrainingValue = {
  //     type: {
  //       value: showTraining?.filters["Training Type"][0].value,
  //       label: showTraining?.filters["Training Type"][0].value,
  //       id: showTraining?.filters["Training Type"][0].id,
  //     },
  //     region: showTraining?.filters["Region"].map((item) => ({
  //       value: item?.value,
  //       label: item?.value,
  //       id: item?.id,
  //     })),
  //     skill: showTraining?.filters["Proficiency"]?.map((item) => ({
  //       value: item?.value,
  //       label: item?.value,
  //       id: item?.id,
  //     })),
  //     fss: showTraining?.filters["FSS"].map((item) => ({
  //       value: item?.value,
  //       label: item?.value,
  //       id: item?.id,
  //     })),
  //     industry: showTraining?.filters["Industry"].map((item) => ({
  //       value: item?.value,
  //       label: item?.value,
  //       id: item?.id,
  //     })),
  //   };
  // }
  let defaultTrainingValue = {};
  if (showTraining) {
    defaultTrainingValue = Object.keys(showTraining.filters).reduce(
      (acc, key) => {
        acc[key] = showTraining.filters[key].map((item) => ({
          value: item?.value,
          label: item?.value,
          id: item?.id,
        }));
        return acc;
      },
      {}
    );
  }
  // const [filterOptions, setFilterOptions] = useState(null);
  const [isBulkUpload, setBulkUpload] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();
  const [isLoading, setIsLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const format = "HH:mm";
  const [selectedTime, setSelectedTime] = useState(
    showTraining ? parseTimeFromString(formData?.duration) : null
  );

  const renderDynamicFields = () => {
    const show_items = formData.dynamicFields.filter(
      (field) => filterOptions[Object.keys(field)[0]].length > 0
    );
    const is_problem = show_items.length % 3 === 1;
    return (
      <div className="form-row-3">
        {show_items.map((field, index) => (
          <div className="form-group-col" key={index}>
            <label className="center-align">
              <img src="/assets/_.svg" />
              &nbsp;{Object.keys(field)[0]}
            </label>
            <Select
              isMulti
              name={field}
              defaultValue={defaultTrainingValue[Object.keys(field)[0]]}
              isDisabled={showTraining && !isEditTraining}
              options={filterOptions[Object.keys(field)[0]]}
              onChange={(e) => {
                handleSelectChange(index, Object.keys(field)[0], e);
              }}
              styles={customStyles}
            />
          </div>
        ))}
        <div className="form-group-col">
          <label className="center-align">
            &nbsp;Duration {"("}HH:MM{")"}
          </label>
          <TimePicker
            name="duration"
            showNow={false}
            onChange={(time, timeString) => {
              handleTime(time, timeString, "duration");
            }}
            placeholder="Select time"
            format={format}
            defaultValue={selectedTime}
            allowClear
            disabled={showTraining && !isEditTraining}
            className="time-picker"
          />
        </div>
        {is_problem && <div className="form-group-col"></div>}
      </div>
    );
  };
  const onUploadChange = (value) => {
    setBulkUpload(!value);
    return;
  };
  useEffect(() => {
    if (disable) setDisable(false);
  }, [formData]);
  const handleValidation = async (e) => {
    e.preventDefault();
    if (disable) return;
    let errorStatus = [];
    if (showTraining && !isEditTraining) return;
    let feilds = formData.dynamicFields;
    feilds = formData.dynamicFields.filter(
      (field) => filterOptions[Object.keys(field)[0]].length > 0
    );
    for (let i = 0; i < feilds.length; i++) {
      let value = feilds[i][Object.keys(feilds[i])[0]];
      if (value.length === 0 || value === undefined) {
        message.error(`Please add details for all the fields.`);
        errorStatus.push(`Please add details for all the fields.`);
        setDisable(true);
        setTimeout(() => {
          setDisable(false);
        }, 3500);
        return;
      }
    }
    if (formData?.link?.toLowerCase().slice(0, 4) !== "http") {
      message.error(
        `The link is invalid. Please add a link starting with 'http'.`
      );
      errorStatus.push(
        `The link is invalid. Please add a link starting with 'http'.`
      );
    }
    if (!containsAlphabetsOrNumbers(formData?.name)) {
      message.error(`Invalid Name`);
      errorStatus.push(`Invalid Name!!`);
    }
    if (!containsAlphabetsOrNumbers(formData?.description)) {
      message.error(`Invalid Description`);
      errorStatus.push(`Invalid Description`);
    }
    // if (!containsAlphabetsOrNumbers(formData?.duration)) {
    //   message.error(`Invalid Duration`);
    //   errorStatus.push(`Invalid Duration`);
    // }
    if (errorStatus.length !== 0) {
      setDisable(true);
      setTimeout(() => {
        setDisable(false);
      }, 3500);
      return;
    }
    setOpenModal(true);
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (isLoading) return;
    setIsLoading(true);
    let payload = {
      name: formData?.name,
      description: formData?.description,
      link: formData?.link,
      duration: formData?.duration,
      filters: formData?.dynamicFields.reduce((result, entry) => {
        const [key, value] = Object.entries(entry)[0];
        result[key] = value;
        return result;
      }, {}),
      training_id: showTraining?.id,
    };
    let add_trainings_body = {
      name: formData?.name,
      description: formData?.description,
      link: formData?.link,
      duration: formData?.duration,
      filters: formData?.dynamicFields.reduce((result, entry) => {
        const [key, value] = Object.entries(entry)[0];
        result[key] = value;
        return result;
      }, {}),
    };
    let accessToken = await getToken();
    let response = await fetch(`${Base_Url_Admin}/trainings`, {
      method: isEditTraining ? "PATCH" : "POST",
      headers: {
        "Content-Type": "application/json",
        // Authorization: "Bearer " + token,
        "X-Api-Token": accessToken,
        // Authorization: "Bearer " + deloitteChatToken,
      },
      body: JSON.stringify(isEditTraining ? payload : add_trainings_body),
    });
    let message = await response.json();
    if (!response.ok) {
      messageApi.open({
        type: "error",
        content: message?.message,
      });
      setTimeout(() => {
        // After the operation is complete, set loading state back to false
        setIsLoading(false);
        setOpenModal(false);
      }, 1500);

      return;
    }
    messageApi.open({
      type: "success",
      content: message?.message,
    });
    setTimeout(() => {
      // After the operation is complete, set loading state back to false
      setIsLoading(false);
      setOpenModal(false);
      getRecommendedTrainings(page);
      setViewSection(false);
      setEditTraining(false);
      setShowTraining(null);
    }, 1500);

    return;
  };

  const autoExpandTextarea = (event) => {
    const textarea = event ? event.target : textareaRef.current;
    textarea.style.height = "auto";
    textarea.style.height = `${textarea.scrollHeight}px`;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSelectChange = (index, fieldName, e) => {
    const values = e.map((item) => item.id);
    setFormData((prevData) => ({
      ...prevData,
      dynamicFields: prevData.dynamicFields.map((field, i) =>
        i === index ? { ...field, [fieldName]: values } : field
      ),
    }));
  };

  const handleTime = (e, timeString, name) => {
    setSelectedTime(e);
    let formatted_date = "";
    if (e) {
      if (e.$H !== 0) formatted_date = e.$H + "h";
      if (e.$m !== 0) formatted_date = formatted_date + " " + e.$m + "m";
    }
    setFormData({ ...formData, [name]: formatted_date });
  };
  useEffect(() => {
    autoExpandTextarea();
  }, []);
  if (filterOptions.length === 0) return <Spin />;
  return (
    <>
      {contextHolder}
      <div className="upload-heading-box">
        <div className="left-side-heading">
          <span className="upload-content">
            {showTraining ? "Manage Resources" : "Add Resources"}
          </span>
          <span className="sub-content">
            {isBulkUpload
              ? "Upload multiple training resources for PPMDs in one go through this section."
              : showTraining
              ? isEditTraining
                ? "Manage the details for an existing learning resource for PPMDs through this section. "
                : "Access the details of an existing learning resource for PPMDs through this section. "
              : "Add a new learning resource for PPMDs through this section. To add multiple resources at once, use the bulk upload option."}
          </span>
        </div>
        <div className="right-side-button-box">
          {showTraining ? (
            isEditTraining ? (
              <div
                className="delete-button"
                onClick={() => setDeleteTraining(showTraining?.id)}
              >
                Delete
              </div>
            ) : (
              <>
                <div
                  className="delete-button"
                  onClick={() => setDeleteTraining(showTraining?.id)}
                >
                  Delete
                </div>
                <div
                  className="edit-button"
                  onClick={() => setEditTraining(true)}
                >
                  Edit
                </div>
              </>
            )
          ) : (
            <div className="bulk-upload-box">
              Bulk Upload:{" "}
              <Switch
                size="medium"
                checked={isBulkUpload}
                onChange={() => onUploadChange(isBulkUpload)}
              />
            </div>
          )}
        </div>
      </div>
      {isBulkUpload ? (
        <BulkUpload
          filterOptions={filterOptions}
          setViewSection={setViewSection}
          getRecommendedTrainings={getRecommendedTrainings}
          page={page}
        />
      ) : (
        <>
          <form>
            <div className="upload-form-box">
              <div className="form-row">
                <div className="form-group-col-1">
                  <label className="center-align">
                    <img src="/assets/_.svg" />
                    &nbsp;Resource Name
                  </label>
                  <input
                    type="text"
                    // defaultValue={defaultTrainingValue?.name}
                    // value={defaultTrainingValue?.name}
                    disabled={showTraining && !isEditTraining}
                    // placeholder="example"
                    className="input-box-form"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                  />
                </div>
                <div className="form-group-col-1">
                  <label className="center-align">
                    <img src="/assets/_.svg" />
                    &nbsp;Link
                  </label>
                  <input
                    type="text"
                    disabled={showTraining && !isEditTraining}
                    className="input-box-form link"
                    name="link"
                    value={formData.link}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className="form-row">
                <div className="form-group-col-des">
                  <label className="center-align">
                    <img src="/assets/_.svg" />
                    &nbsp;Description
                  </label>
                  <textarea
                    ref={textareaRef}
                    name="description"
                    value={formData.description}
                    onChange={handleChange}
                    disabled={showTraining && !isEditTraining}
                    className="textarea-box-form"
                    onInput={autoExpandTextarea}
                  />
                </div>
              </div>
              {renderDynamicFields()}
            </div>
          </form>
          {showTraining && !isEditTraining ? (
            <></>
          ) : (
            <div className="submit-box">
              <button onClick={handleValidation} className="submit-button">
                Submit
              </button>
            </div>
          )}
        </>
      )}
      <Modal
        open={openModal}
        title={
          <div className="delete-title-box">
            <img src="/assets/ExclamationCircle.svg" />
            <span className="title-content">
              Are you sure you want to submit these details?
            </span>
          </div>
        }
        width="26rem"
        centered
        footer={[
          <div className="delete-modal">
            <div className="no-box" onClick={() => setOpenModal(false)}>
              No
            </div>
            <div className="yes-box" onClick={handleSubmit}>
              {isLoading ? "Loading..." : "Yes"}
            </div>
          </div>,
        ]}
        onCancel={() => setOpenModal(false)}
      ></Modal>
    </>
  );
}